import HeaderComponent from 'src/layout/header/header.component';
import FooterComponent from 'src/layout/footer/footer.component';
import { Outlet } from 'react-router-dom';
// import MaintenancePage from '../maintenance-page/maintenance.page';

export default function HeaderFooterLayout() {
	return (
		<>
			{/* *~~*~~*~~ LAYOUT ~~*~~*~~* */}
			<HeaderComponent />

			<div className="h-headerP" />
			<div className="  py-[20px]  ">
				<Outlet />
			</div>

			{/* <div className="  p-[120px]  ">
				<MaintenancePage />
			</div> */}
			<FooterComponent />

		</>
	);
}
