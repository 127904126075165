import { signal, type Signal } from '@preact/signals-react';
import { type AppStorage } from 'src/shared/models/global/app-storage.model';
import STORAGE_KEYS from 'src/static/storage.keys';
import QueryApi from 'src/shared/api/query-api';
import { APP_EVENTS } from 'src/static/enums/app.events';
import { UserData } from 'src/shared/models/user/user.model';
import { toast } from 'react-toastify';
import PwrService from '../pwr/pwr.service';
import { WalletData } from 'src/shared/models/walletData/walletData.model';

export default class AuthService {
	private authenticated: Signal<boolean> = signal(false);
	private token: string | null = null;

	// Inject PwrService via constructor
	constructor(private appStorage: AppStorage, private pwrSvc: PwrService) {
		this.pwrSvc = pwrSvc;
	}
	// Keep the initializeProvider method
	initializeProvider() {
		this.pwrSvc.initializeProvider();
	}

	async init(): Promise<void> {
		await this.restoreSession();
	}

	async connect(): Promise<any> {
		return this.pwrSvc.connect();
	}
	async auth(): Promise<void> {
		// const isAlreadyConnected = localStorage.getItem('isAlreadyConnected') === 'true';

		const walletArray = await this.pwrSvc.connect(); // This returns a promise that resolves to an array
		const wallet = walletArray[0]; // Access the first wallet object directly

		// Check if wallet is defined
		// if (!wallet) {
		// 	toast.error("Wallet connection issue: No wallet found.");
		// 	throw new Error("Wallet is undefined.");
		// }

		// if (!Array.isArray(wallet.addresses) || wallet.addresses.length === 0) {
		// 	toast.error("Wallet connection issue: No address found.");
		// 	toast.info("Please disable and enable the wallet extension.");
		// 	throw new Error("Wallet addresses array is empty or undefined.");
		// }
		const address = wallet.address;
		console.log("THIS IS THE WALLET ADDRESS: ", address)
		localStorage.setItem('walletAddress', address); // Save the address to local storage

		const { user } = await QueryApi.user.getInfo(address);

		const loggedInEvent = new CustomEvent<WalletData>(APP_EVENTS.WALLET_AUTH, {
			detail: {
				address: user.address,
				username: user.username || 'Unknown User',
			},
		});
		document.dispatchEvent(loggedInEvent);

		this.authenticated.value = true;

	}

	isLoggedIn() {
		return this.authenticated.value;
	}

	async login(): Promise<void> {
		this.authenticated.value = true;
	}

	async twitterLogin(token: string): Promise<{ token: string }> {
		try {
			const res = await QueryApi.auth.twitterSignin(token);

			// Save token and session data
			this.token = res.token;
			if (!this.token) {
				throw new Error('No token received from the server');
			}
			localStorage.setItem('authToken', this.token);
			this.appStorage.set(STORAGE_KEYS.auth_session, true);

			const loggedInEvent = new CustomEvent<UserData>(APP_EVENTS.AUTH_LOGGED_IN, {
				detail: {
					id: res.user.id,
					name: res.user.name,
					pfp: res.user.pictureUrl || 'default-pfp-url',
					role: res.user.role,
					flag: res.user.flag,
				},
			});

			document.dispatchEvent(loggedInEvent);

			this.authenticated.value = true;
			return { token: this.token };
		} catch (err) {
			toast.error('Error while trying to authenticate with Twitter');
			throw err;
		}
	}
	async logout(): Promise<void> {
		try {
			await QueryApi.auth.twitterLogOut();
			this.authenticated.value = false;  // Update the state to reflect the logout status
			localStorage.removeItem('authToken'); // Clear the token from local storage
			// Handle any session clearing or necessary cleanup

			// Dispatch logged-out event
			const loggedOutEvent = new CustomEvent(APP_EVENTS.AUTH_LOGGED_OUT);
			document.dispatchEvent(loggedOutEvent);

		} catch (err) {
			toast.error('Error logging out');
		}
	}

	public async restoreSession(): Promise<void> {
		const session = this.appStorage.get<boolean>(STORAGE_KEYS.auth_session);
		const token = localStorage.getItem('bearerToken');

		if (session && token) {
			try {
				const sessionInfo = await QueryApi.auth.session();
				const loggedInEvent = new CustomEvent<UserData>(APP_EVENTS.AUTH_LOGGED_IN, {
					detail: {
						id: sessionInfo.id,
						name: sessionInfo.name,
						pfp: sessionInfo.profile_image_url,
						role: sessionInfo.role,
						flag: sessionInfo.flag,
					},
				});

				document.dispatchEvent(loggedInEvent);
				this.authenticated.value = true;
			} catch (err) {
				console.error('Error restoring session:', err);
				await this.logout();
			}
		}
	}

	public async checkSessionIsStillValid(): Promise<boolean> {
		try {
			await QueryApi.auth.session();
			return true;
		} catch (err) {
			this.logout();
			return false;
		}
	}
}
